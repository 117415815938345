<template>
  <div>
    <!--====== Header Start ======-->
    <header
      class="header-two animate__animated animate__fadeInDown animate__delay-.5s"
      ref="header"
    >
      <div class="header-navigation">
        <div class="header-navigation_flex">
          <div class="header-left" @click="toHanldeHome()">
            <a href="javascript:;"></a>
          </div>
          <div class="header-right">
            <div class="site-nav-menu">
              <!-- PC导航展示 -->
              <ul
                v-if="$store.state.widthWidow > 1200"
                class="primary-menu"
                @mouseover="mouseEnter"
                @mouseout="mouseLeave"
              >
                <li @mouseover="navHandleClickOne()">
                  <router-link to="/cathaya-services">SERVICES</router-link>
                </li>
                <li @mouseover="navHandleClickTwo()">
                  <router-link to="#">SOLUTIONS</router-link>
                </li>
                <li @mouseover="navHandleClickThr()">
                  <router-link to="/cathaya-insights">INSIGHTS</router-link>
                </li>
                <li @mouseover="navHandleClickFour()">
                  <router-link to="/cathaya-careerForm">CONTACT</router-link>
                </li>
              </ul>
              <!-- 移动端nav展示 -->
              <ul
                v-if="$store.state.widthWidow <= 1200"
                class="primary-menu"
                @mouseover="mouseEnter"
                @mouseout="mouseLeave"
              >
                <li>
                  <span class="primary-menu_sp" @click="viewFisrt()">
                    <router-link to="/cathaya-services">SERVICES</router-link>
                  </span>
                  <span class="primary-menu_scond" @click="navHandleClickOne()"
                    ><i class="el-icon-arrow-right"></i
                  ></span>
                </li>
                <li>
                  <span class="primary-menu_sp" @click="viewFisrt()">
                    <router-link to="/cathaya-solutions">SOLUTIONS</router-link>
                  </span>
                  <span class="primary-menu_scond" @click="navHandleClickTwo()"
                    ><i class="el-icon-arrow-right"></i
                  ></span>
                </li>
                <li>
                  <span class="primary-menu_sp" @click="viewFisrt()">
                    <router-link to="/cathaya-insights">INSIGHTS</router-link>
                  </span>
                  <span class="primary-menu_scond" @click="navHandleClickThr()"
                    ><i class="el-icon-arrow-right"></i
                  ></span>
                </li>
                <li>
                  <span class="primary-menu_sp" @click="viewFisrt()">
                    <router-link to="/cathaya-careerForm">CONTACT</router-link>
                  </span>
                  <span class="primary-menu_scond" @click="navHandleClickFour()"
                    ><i class="el-icon-arrow-right"></i
                  ></span>
                </li>
                <div
                  class="mobile_navbar"
                  v-if="$store.state.widthWidow <= 1200"
                >
                  <div class="navbar-btn navbar-btn--after" v-if="cookie">
                    <a
                      :href="
                        $store.state.NODE_ENV === 'development'
                          ? 'http://quotationserver.141data-med.com.cn//home'
                          : 'https://u.cathayaresearch.com/home'
                      "
                      >Get Quote</a
                    >
                    <a href="javascript:;" @click="loginOut">Log Out</a>
                  </div>
                  <div class="navbar-btn" v-else>
                    <a href="javascript:;" @click="login">Client Login</a>
                  </div>
                </div>
              </ul>
              <div
                class="menuBox"
                v-show="menuBoxSum"
                @mouseleave="navHandleClickLeave()"
              >
                <div class="submenu" v-show="isShow2">
                  <div class="submenu_show">
                    <div class="submenu_box" @click="navHandleClickShow()">
                      <div class="submenu_box_img">
                        <router-link to="/cathaya-services"
                          ><img
                            src="https://feresources.oss-cn-beijing.aliyuncs.com/statics/pc/cathaya_official/assets/cathaya/nav-serves.png"
                            alt=""
                          />
                        </router-link>
                      </div>
                      <div class="submenu_box_link">
                        <router-link to="/cathaya-services"
                          >Services</router-link
                        >
                        <span class="hx"> </span>
                      </div>
                    </div>
                    <div class="submenu_main">
                      <!-- pc展示 -->
                      <div class="submenu_main_flx">
                        <ul
                          class="submenu_main_ul"
                          v-if="$store.state.widthWidow > 1200"
                        >
                          <li
                            @mouseover="subItemClick(index)"
                            v-for="(item, index) in seviceSubMenuList"
                            :class="{ active: index === isActive }"
                            :key="index"
                          >
                            <router-link :to="item.link"
                              ><span>{{ item.title }}</span
                              ><i class="el-icon-arrow-right"></i
                            ></router-link>
                          </li>
                        </ul>
                        <!-- wap移动展示 -->
                        <ul
                          class="submenu_main_ul"
                          v-if="$store.state.widthWidow <= 1200"
                        >
                          <li
                            v-for="(item, index) in seviceSubMenuList"
                            :class="{ active: index === isActive }"
                            :key="index"
                          >
                            <span @click="viewFisrt()">
                              <router-link :to="item.link">{{
                                item.title
                              }}</router-link>
                            </span>
                            <span
                              class="submenu_main_scond"
                              @click="subItemClick(index)"
                              ><i class="el-icon-arrow-right"></i
                            ></span>
                          </li>
                        </ul>
                        <div class="submenu_main_box">
                          <div
                            v-show="isShowItem1"
                            class="submenu_main_box_tab1"
                          >
                            <el-row>
                              <el-col>
                                <router-link
                                  @click.native="navHandleClickShow()"
                                  ref="cathayaQualitative"
                                  to="/cathaya-qualitative"
                                  >Qualitative Research</router-link
                                >
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col>
                                <router-link
                                  @click.native="navHandleClickShow()"
                                  to="/cathaya-quantitative"
                                >
                                  Quantitative Research</router-link
                                >
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col>
                                <router-link
                                  @click.native="navHandleClickShow()"
                                  to="/cathaya-industryAnalysis"
                                >
                                  Industry Analysis</router-link
                                >
                              </el-col>
                            </el-row>
                          </div>
                          <div
                            v-show="isShowItem2"
                            class="submenu_main_box_tab2"
                          >
                            <el-row>
                              <el-col :span="12">
                                <router-link
                                  @click.native="navHandleClickShow()"
                                  to="/cathaya-sampleService"
                                  >Panel & Sample Service</router-link
                                >
                              </el-col>
                              <el-col :span="12">
                                <router-link
                                  @click.native="navHandleClickShow()"
                                  to="/cathaya-translationServices"
                                >
                                  Translation Services</router-link
                                >
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <router-link
                                  @click.native="navHandleClickShow()"
                                  to="/cathaya-fieldworkManagement"
                                >
                                  Global Fieldwork Management</router-link
                                >
                              </el-col>
                              <el-col :span="12">
                                <router-link
                                  @click.native="navHandleClickShow()"
                                  to="/cathaya-localizationSupport"
                                >
                                  Localization Support</router-link
                                >
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <router-link
                                  @click.native="navHandleClickShow()"
                                  to="/cathaya-facilityRental"
                                  >Facility Rental</router-link
                                >
                              </el-col>

                              <el-col :span="12">
                                <router-link
                                  @click.native="navHandleClickShow()"
                                  to="/cathaya-multiculturalResearch"
                                >
                                  Multicultural Research &
                                  Consultation</router-link
                                >
                              </el-col>
                            </el-row>
                          </div>
                          <div
                            v-show="isShowItem3"
                            class="submenu_main_box_tab3"
                          >
                            <el-row>
                              <el-col>
                                <router-link
                                  @click.native="navHandleClickShow()"
                                  to="/cathaya-data-solutions-survey"
                                >
                                  Survey Programming & Hosting</router-link
                                >
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col>
                                <router-link
                                  @click.native="navHandleClickShow()"
                                  to="/cathaya-data-solutions-advance"
                                >
                                  Advanced Analytics</router-link
                                >
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col>
                                <router-link
                                  @click.native="navHandleClickShow()"
                                  to="/cathaya-data-solutions-software"
                                >
                                  Software & Data Solution
                                  Development</router-link
                                >
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="submenu" v-show="isShow3">
                  <div class="submenu_show">
                    <div class="submenu_box" @click="navHandleClickShow()">
                      <div class="submenu_box_img">
                        <router-link to="/cathaya-solutions"
                          ><img
                            src="https://feresources.oss-cn-beijing.aliyuncs.com/statics/pc/cathaya_official/assets/cathaya/nav-solutions.png"
                            alt=""
                          />
                        </router-link>
                      </div>
                      <div class="submenu_box_link">
                        <router-link to="/cathaya-solutions"
                          >Solutions</router-link
                        >
                        <span class="hx"> </span>
                      </div>
                    </div>
                    <div class="submenu_main">
                      <div class="submenu_main_about">
                        <el-row>
                          <el-col :xs="24" :sm="12" :md="12" :lg="8">
                            <a
                              :href="
                                $store.state.NODE_ENV === 'development'
                                  ? 'http://fusion.cathayaquote.live/'
                                  : 'http://cathayafusion.com'
                              "
                              ><span @click="viewFisrt()"
                                >Custom Insight Community</span
                              ></a
                            >
                          </el-col>
                          <el-col :sm="12" :md="12" :lg="8">
                            <a
                              :href="
                                $store.state.NODE_ENV === 'development'
                                  ? 'http://panel.cathayaquote.live/'
                                  : 'http://cathayapanel.com'
                              "
                              ><span @click="viewFisrt()">Cathaya Panel</span>
                            </a>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :sm="12" :md="12" :lg="8">
                            <a
                              :href="
                                $store.state.NODE_ENV === 'development'
                                  ? 'http://survey.cathayaquote.live/'
                                  : 'http://cathayasurvey.com'
                              "
                              ><span @click="viewFisrt()"
                                >Cathaya Survey Platform</span
                              >
                            </a>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="submenu" v-show="isShow4">
                  <div class="submenu_show">
                    <div class="submenu_box" @click="navHandleClickShow()">
                      <div class="submenu_box_img">
                        <router-link to="/cathaya-insights"
                          ><img
                            src="https://feresources.oss-cn-beijing.aliyuncs.com/statics/pc/cathaya_official/assets/cathaya/nav-insights.png"
                            alt=""
                          />
                        </router-link>
                      </div>
                      <div class="submenu_box_link">
                        <router-link to="/cathaya-insights"
                          >Insights</router-link
                        >
                        <span class="hx"> </span>
                      </div>
                    </div>
                    <div class="submenu_main">
                      <div class="submenu_main-content">
                        <div class="submenu_main_rg">
                          <el-row>
                            <el-col>
                              <span @click="getTopicVal('5')">
                                <router-link to="/cathaya-science">
                                  Market Research</router-link
                                >
                              </span>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col>
                              <!-- <span @click="getTopicVal(1)"> -->
                              <span @click="getTopicVal('articles_newsletter')">
                                <router-link to="/cathaya-science">
                                  Global Insights</router-link
                                >
                              </span>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col>
                              <!-- <span @click="getTopicVal(2)"> -->
                              <span @click="getTopicVal('case_reports')">
                                <router-link to="/cathaya-science">
                                  Cases & Reports</router-link
                                >
                              </span>
                            </el-col>
                          </el-row>
                        </div>
                        <div class="submenu_main_lf">
                          <el-row>
                            <el-col :span="14">
                              <span @click="getInsightsVal(4)">
                                <a href="javascript:;">Agriculture</a>
                              </span>
                            </el-col>
                            <el-col :span="10">
                              <span @click="getInsightsVal(5)">
                                <a href="javascript:;">Auto & Industrial</a>
                              </span>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="14">
                              <span @click="getInsightsVal(6)">
                                <a href="javascript:;">Financial Services</a>
                              </span>
                            </el-col>
                            <el-col :span="10">
                              <span @click="getInsightsVal(1)">
                                <a href="javascript:;">Healthcare & Pharma</a>
                              </span>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="14">
                              <span @click="getInsightsVal(3)">
                                <a href="javascript:;">High-Tech & Telecom</a>
                              </span>
                            </el-col>
                            <el-col :span="10">
                              <span @click="getInsightsVal(2)">
                                <a href="javascript:;">Life Science</a>
                              </span>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="submenu" v-show="isShow5">
                  <div class="submenu_show">
                    <div class="submenu_box" @click="navHandleClickShow()">
                      <div class="submenu_box_img">
                        <router-link to="/cathaya-careerForm"
                          ><img
                            src="https://feresources.oss-cn-beijing.aliyuncs.com/statics/pc/cathaya_official/assets/cathaya/nav-contact.png"
                            alt=""
                          />
                        </router-link>
                      </div>
                      <div class="submenu_box_link">
                        <router-link to="/cathaya-careerForm"
                          >Contact</router-link
                        >
                        <span class="hx"> </span>
                      </div>
                    </div>
                    <div class="submenu_main">
                      <div class="submenu_main_about">
                        <el-row>
                          <el-col :xs="24" :sm="12" :md="12" :lg="8">
                            <router-link to="/cathaya-about"
                              ><span @click="viewFisrt()"
                                >About</span
                              ></router-link
                            >
                          </el-col>
                          <el-col :xs="24" :sm="12" :md="12" :lg="8">
                            <router-link to="/cathaya-career"
                              ><span @click="viewFisrt()"
                                >Career</span
                              ></router-link
                            >
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :xs="24" :sm="12" :md="12" :lg="8">
                            <router-link to="/cathaya-team"
                              ><span @click="viewFisrt()"
                                >Team</span
                              ></router-link
                            >
                          </el-col>
                          <el-col :xs="24" :sm="12" :md="12" :lg="8">
                            <router-link to="/cathaya-careerForm"
                              ><span @click="viewFisrt()">Contact</span>
                            </router-link>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :xs="24" :sm="12" :md="12" :lg="8">
                            <router-link to="/cathaya-clients"
                              ><span @click="viewFisrt()"
                                >Clients</span
                              ></router-link
                            >
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 语言切换 -->
          <div class="header-extra">
            <div class="menu-cart" @mouseover="navHandleClickFive()">
              <el-dropdown :hide-on-click="false">
                <span class="el-dropdown-link">
                  <img
                    src="https://feresources.oss-cn-beijing.aliyuncs.com/statics/pc/cathaya_official/assets/cathaya/ball.png"
                    style="width: 24px; margin-top: 5px"
                  />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item
                    @click.native="toggleLang('zh')"
                    :disabled="$i18n.locale == 'zh'"
                    >中文</el-dropdown-item
                  > -->
                  <!-- <el-dropdown-item
                    @click.native="toggleLang('ch')"
                    >中文</el-dropdown-item
                  > -->
                  <el-dropdown-item @click.native="toggleLang('en')"
                    >English</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- 搜索按钮 -->
            <div class="search-widget">
              <a
                href="javascript:;"
                @mouseover="navHandleClickFive()"
                @click="searchKeyWord"
                class="search-icon"
              >
                <!-- <i class="el-icon-search"></i> -->
                <img
                  src="https://feresources.oss-cn-beijing.aliyuncs.com/statics/pc/cathaya_official/assets/cathaya/search.png"
                  style="width: 24px; margin-top: 5px"
                />
              </a>
            </div>
            <!-- 大于1200 登陆按钮 -->
            <div v-if="$store.state.widthWidow > 1200">
              <div class="navbar-btn navbar-btn--after" v-if="this.cookie">
                <a
                  :href="
                    $store.state.NODE_ENV === 'development'
                      ? 'http://quotationserver.141data-med.com.cn/home'
                      : 'https://u.cathayaresearch.com/home'
                  "
                  >Get Quote</a
                >
                <a href="javascript:;" @click="loginOut">Log Out</a>
              </div>
              <div class="navbar-btn" v-else>
                <a href="javascript:;" @click="login()">Client Login</a>
              </div>
            </div>
            <!-- 移动端显示的图标 -->
            <div class="nav-toggler" @click="wapHandle">
              <span></span><span></span><span></span>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="searchHead" v-show="searchIsShow">
      <div
        class="searchHead_nav animate__animated animate__fadeInDown animate__faster"
      >
        <div
          class="searchHead_nav_int"
          :style="{
            width: this.cookie && $store.state.widthWidow > 768 ? '650px' : '',
          }"
        >
          <el-autocomplete
            v-if="$store.state.widthWidow > 1200"
            v-model.trim="searchKeyTagValue"
            :fetch-suggestions="querySearch"
            @select="handlename"
            @keyup.enter.native="getSearchHandle"
            placeholder="Search for Articles , Cases and Reports"
            :trigger-on-focus="false"
          >
          </el-autocomplete>
          <el-input
            v-else
            v-model.trim="searchKeyTagValue"
            @change="handlename"
            placeholder="Search for Articles , Cases and Reports"
          ></el-input>
          <a
            href="javascript:;"
            class="searchHead_nav_int_search"
            @click="getSearchHandle()"
            ><i class="cat-icon-search"></i
          ></a>
          <a
            href="javascript:;"
            class="searchHead_nav_int_close"
            @click="closeSearch"
            ><i class="el-icon-close"></i
          ></a>
        </div>
        <div class="searchHead_nav_search">
          <h3>Recommended Searches</h3>
          <ul>
            <li
              v-for="(item, index) in searchsList"
              @click="getSearchQua(item)"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import insightsApi from "@/api/insights";
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: "CathayaHome",
  components: {},
  data() {
    return {
      env: "",
      wapShow: false,
      isShow2: false,
      isShow3: false,
      isShow4: false,
      isShow5: false,
      menuBoxSum: false,
      isShowItem1: false,
      isShowItem2: false,
      isShowItem3: false,
      isActive: 0,
      searchIsShow: false, //搜索展示隐藏
      clearSearchValue: "",
      windoWidth: "", //获取当前的看度
      seviceSubMenuList: [
        {
          link: "/cathaya-customResearch",
          title: "Custom Research",
        },
        {
          link: "/cathaya-globalProject",
          title: "Global Project Management",
        },
        {
          link: "/cathaya-dataSolutions",
          title: "Data Solutions",
        },
      ],
      searchKeyTagValue: "",
      searchsList: [
        "Qualitative",
        "Quantitative",
        "Online survey",
        "Smartphone",
        "B2B",
        "IDI",
      ],
    };
  },
  created() {},
  mounted() {
    document.title = "Cathaya Research – Global Data and Insights Expert";
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.breakpointCheck);
    this.breakpointCheck();

    if (location.pathname === "/") {
      location.pathname = "/cathaya-home";
    }
    // 临时用到
    // if(this.$store.state.NODE_ENV !== 'development'){
    //   if(location.pathname == '/cathaya-home'){
    //     this.$router.push({
    //       path: '/cathayaSolution',
    //     })
    //   }
    // }

    var getUrlRefresh = location.pathname.match("[^/]+(?!.*/)")[0];
    if (getUrlRefresh === "cathaya-home") {
      $(this.$refs.header).css("background", "");
    } else {
      $(this.$refs.header).css("background", "#fff");
    }
  },
  methods: {
    wapHandle() {
      $(".header-two").css("background", "#fff");
      if ($(".nav-toggler").hasClass("menu-opened")) {
        // 取消禁止滚动
        $("html,body").removeAttr("style");
        // 取消禁止滚动结束
        $(".breakpoint-on .nav-toggler").removeClass("menu-opened");
        $(".breakpoint-on .site-nav-menu").hide();
        $(".breakpoint-on .site-nav-menu .menuBox .submenu").hide();
      } else {
        // 禁止滚动
        var wHeight = $(window).height();
        $("html,body").css({ height: wHeight, overflow: "hidden" });
        // 禁止滚动结束

        $(".breakpoint-on .nav-toggler").addClass("menu-opened");
        $(".breakpoint-on .site-nav-menu").show();
        $(".breakpoint-on .submenu_main_ul").hide(); //二级导航隐藏
        $(".breakpoint-on .submenu_main_about").hide(); //二级导航隐藏
        $(".breakpoint-on .primary-menu").show();
      }
    },
    breakpointCheck() {
      this.$store.state.widthWidow = window.innerWidth; // 获取视图宽度，
      if (this.$store.state.widthWidow > 1200) {
        $(".header-navigation .site-nav-menu").show(); //pc端导航显示
        $(".header-navigation .primary-menu").show(); //pc端导航显示
        $(".submenu_main_ul").show(); //二级导航显示
        $(".header-navigation").removeClass("breakpoint-on");
      } else {
        $(".header-navigation").addClass("breakpoint-on");
        $(".nav-toggler").removeClass("menu-opened");
        $(".breakpoint-on .site-nav-menu").hide(); //移动端导航默认显示隐藏
        // 移动端头部样式
        $(".breakpoint-on .primary-menu li .primary-menu_scond").each(function (
          e
        ) {
          $(this).click(function () {
            $(".breakpoint-on .primary-menu").hide(); //一级导航隐藏
            $(".breakpoint-on .submenu_main_ul").show(); //二级导航显示
            $(".breakpoint-on .submenu_main_about").show(); //二级导航solution显示
            $(".breakpoint-on .submenu_main .submenu_main_box").hide(); //三级导航隐藏
            $(".breakpoint-on .menuBox .submenu")
              .eq(e)
              .show()
              .siblings()
              .hide();
          });
        });
        $(".breakpoint-on .submenu_main_ul li .submenu_main_scond").each(
          function () {
            $(this).click(function () {
              $(".breakpoint-on .submenu_main_ul").hide(); //二级导航隐藏
              $(".breakpoint-on .submenu_main .submenu_main_box").show();
              $(".breakpoint-on .submenu_main_about").hide(); //二级导航solution显示
            });
          }
        );
      }
    },
    viewFisrt() {
      $(".breakpoint-on .site-nav-menu").hide();
      $(".nav-toggler").removeClass("menu-opened");
      // 取消禁止滚动
      $("html,body").removeAttr("style");
    },
    navHandleClickOne() {
      this.isActive = "";
      this.menuBoxSum = true;
      this.isShow2 = true;
      this.isShow3 = false;
      this.isShow4 = false;
      this.isShow5 = false;
      if (this.isShow2) {
        $(this.$refs.header).css("background", "#fff");
      }
    },
    navHandleClickTwo() {
      this.menuBoxSum = true;
      this.isShow2 = false;
      this.isShow3 = true;
      this.isShow4 = false;
      this.isShow5 = false;
      if (this.isShow2) {
        $(this.$refs.header).css("background", "#fff");
      }
    },
    navHandleClickThr() {
      this.menuBoxSum = true;
      this.isShow4 = true;
      this.isShow2 = false;
      this.isShow3 = false;
      this.isShow5 = false;
      $(this.$refs.slide).show();
      if (this.isShow2) {
        $(this.$refs.header).css("background", "#fff");
      }
    },
    navHandleClickFour() {
      this.menuBoxSum = true;
      this.isShow5 = true;
      this.isShow3 = false;
      this.isShow4 = false;
      this.isShow2 = false;
      if (this.isShow2) {
        $(this.$refs.header).css("background", "#fff");
      }
    },
    navHandleClickFive() {
      $(this.$refs.header).css("background", "#fff");
    },
    navHandleClickLeave() {
      this.menuBoxSum = false;
      this.isShow2 = false;
      this.isShow3 = false;
      this.isShow4 = false;
      this.isShow5 = false;
      this.menuBoxSum = false;
      this.isShowItem1 = false;
      this.isShowItem2 = false;
      this.isShowItem3 = false;
    },
    navHandleClickShow() {
      this.menuBoxSum = false;
      this.isShow2 = false;
      this.isShow3 = false;
      this.isShow4 = false;
      this.isShow5 = false;
      //移动端按钮显示隐藏
      $(".breakpoint-on .site-nav-menu").hide();
      if ($(".nav-toggler").hasClass("menu-opened")) {
        $(".nav-toggler").removeClass("menu-opened");
        $("html,body").removeAttr("style"); // 取消禁止滚动
      } else {
        $(".breakpoint-on .site-nav-menu").show();
        $(".nav-toggler").addClass("menu-opened");
      }
    },
    subItemClick(index) {
      this.isActive = index;
      if (index === 0) {
        this.isShowItem1 = true;
        this.isShowItem2 = false;
        this.isShowItem3 = false;
      } else if (index === 1) {
        this.isShowItem1 = false;
        this.isShowItem2 = true;
        this.isShowItem3 = false;
      } else if (index === 2) {
        this.isShowItem1 = false;
        this.isShowItem2 = false;
        this.isShowItem3 = true;
      }
    },
    handleScroll() {
      //获取地址斜杠/字段
      var urlStr = location.pathname.match("[^/]+(?!.*/)")[0];
      const scrolltop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrolltop > 0) {
        $(this.$refs.header).css("background", "#fff");
        if (this.$store.state.widthWidow > 768) {
          this.searchIsShow = false;
        }
      } else {
        if (this.isShow2 || this.isShow3 || this.isShow4 || this.isShow5) {
          $(this.$refs.header).css("background", "#fff");
          return;
        }
        if (urlStr === "cathaya-home") {
          $(this.$refs.header).css("background", "");
          if ($(".nav-toggler").hasClass("menu-opened")) {
            $(".header-two").css("background", "#fff");
          } else {
            $(".header-two").css("background", "");
          }
        } else {
          $(this.$refs.header).css("background", "#fff");
        }
        if (this.searchIsShow) {
          $(this.$refs.header).css("background", "#fff");
        }
      }
    },
    mouseEnter() {
      const scrolltop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrolltop === 0) {
        this.$refs.header.style.backgroundColor = "#fff";
      }
    },
    mouseLeave() {
      const scrolltop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrolltop === 0) {
        if (!this.isShow2 && !this.isShow3 && !this.isShow4 && !this.isShow5) {
          if ($(".nav-toggler").hasClass("menu-opened")) {
            $(".header-two").css("background", "#fff");
          } else {
            this.$refs.header.style.backgroundColor = "transparent";
            $(".breakpoint-on .site-nav-menu").hide(); // 移动端隐藏遮罩层
          }
        }
      }
    },
    toHanldeHome() {
      this.$router.push({
        path: "/cathaya-home",
      });
      //点击回到顶部

      if (this.$store.state.widthWidow <= 768) {
        this.$refs.header.style.backgroundColor = "transparent";
        this.isShow2 = false;
        this.isShow3 = false;
        this.isShow4 = false;
        this.isShow5 = false;
        $("html,body").removeAttr("style");
        $(".nav-toggler").removeClass("menu-opened");
        $(".breakpoint-on .site-nav-menu").hide();
      }
      scrollTo(0, 0);
    },
    toggleLang(lang) {
      //22
      window.localStorage.setItem("getLanguage", lang);
      location.reload();
      //语言切换
      //
      // if (lang == "zh") {
      //   localStorage.setItem("locale", "zh");
      //   this.$i18n.locale = localStorage.getItem("locale");
      // } else if (lang == "en") {
      //   localStorage.setItem("locale", "en");
      //   this.$i18n.locale = localStorage.getItem("locale");
      // }
    },
    searchKeyWord() {
      this.searchIsShow = true;
      this.searchKeyTagValue = "";
      this.$store.commit("clearSearchVal");

      // 取消禁止滚动
      $("html,body").removeAttr("style");
      $(".nav-toggler").removeClass("menu-opened");

      //过滤空值与重复的
      if (localStorage.getItem("setSearchValue")) {
        let getSearchLocal = localStorage.getItem("setSearchValue").split(",");
        this.searchsList = [...new Set(getSearchLocal)];
      }
    },
    closeSearch() {
      this.searchIsShow = false;
      $(".breakpoint-on .site-nav-menu").hide(); // 移动端隐藏遮罩层
    },
    getSearchHandle() {
      if (this.searchKeyTagValue === "") return false;
      this.searchsList.unshift(this.searchKeyTagValue);
      localStorage.setItem("setSearchValue", this.searchsList);
      this.$router.push({
        path: "/cathaya-search",
      });
      this.$store.commit("getSearchVal", this.searchKeyTagValue);
      this.searchIsShow = false;
      $(".el-autocomplete-suggestion").hide();
    },
    getSearchQua(item) {
      $(".breakpoint-on .site-nav-menu").hide();
      this.$router.push({
        path: "/cathaya-search",
      });
      this.$store.commit("getSearchVal", item);
      this.searchIsShow = false;
    },
    //获取关键词
    querySearch(queryString, callback) {
      var list = [{}];
      if (queryString && queryString.length > 0) {
        //调取后台接口数据
        insightsApi.getInsightTag().then((res) => {
          if (res.data.code === 0) {
            list = res.data.data.map((item) => {
              //返回自己想要的数据格式
              return {
                id: `${item.id}`,
                value: `${item.name}`,
              };
            });
            //对比过滤出包含的数据
            var list2 = queryString
              ? list.filter(this.createFilter(queryString))
              : list;
            callback(list2);
          }
        });
      }
    },
    createFilter(queryString) {
      //判断是否包含
      return (restaurant) => {
        return restaurant.value.indexOf(queryString) > -1;
      };
    },
    handlename(item) {
      if (this.$store.state.widthWidow <= 768) {
        this.searchKeyTagValue = item;
      } else {
        this.searchKeyTagValue = item.value;
      }
    },
    login() {
      let a = document.createElement("a");
      a.href =
        this.$store.state.NODE_ENV === "development"
          ? "http://quotationserver.141data-med.com.cn/login?redirect=%2Fhome"
          : "https://u.cathayaresearch.com/login?redirect=%2Fhome";
      a.click();
      if (this.$store.state.widthWidow <= 768) {
        // 取消禁止滚动
        $("html,body").removeAttr("style");
        $(".nav-toggler").removeClass("menu-opened");
        $(".breakpoint-on .site-nav-menu").hide(); // 移动端隐藏遮罩层
        this.$refs.header.style.backgroundColor = "transparent";
        a.target = "_blank";
      }
    },
    loginOut() {
      this.$confirm("Are you sure you want to log out?", "", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        customClass: "confirm",
      })
        .then(() => {
          let strCookie = this.cookie.substr(11, this.cookie.length);
          insightsApi.logout(strCookie).then((res) => {
            console.log("登出", res);
            this.$store.commit("clearCookie");
            // document.cookie = 'xsessionid=;domain=.cathayaquote.live;path=/;max-age=0'
            this.delCookie(); // 退出清空cookie
            sessionStorage.clear();
            location.reload();
            this.$router.push({
              path: "/cathaya-home",
            });
          });
        })
        .catch(() => {
          console.log("cancel");
        });
    },
    delCookie() {
      var keys = document.cookie.split(";");
      if (keys) {
        for (var i = keys.length; i--; ) {
          document.cookie =
            keys[i] + "=0;path=/;expires=" + new Date(0).toUTCString(); // 清除当前域名下的,例如：m.ratingdog.cn
          document.cookie =
            keys[i] +
            "=0;path=/;domain=" +
            document.domain +
            ";expires=" +
            new Date(0).toUTCString(); // 清除当前域名下的，例如 .m.ratingdog.cn
          if (this.$store.state.NODE_ENV === "development") {
            document.cookie =
              keys[i] +
              "=0;path=/;domain=.cathayaquote.live;expires=" +
              new Date(0).toUTCString(); // 清除一级域名下的或指定的，例如 .ratingdog.cn
          } else {
            document.cookie =
              keys[i] +
              "=0;path=/;domain=.cathayaresearch.com;expires=" +
              new Date(0).toUTCString(); // 清除一级域名下的或指定的，例如 .ratingdog.cn
          }
        }
      }
    },
    getInsightsVal(val) {
      $(".breakpoint-on .site-nav-menu").hide(); // 移动端隐藏遮罩层
      $(".nav-toggler").removeClass("menu-opened");
      $("html,body").removeAttr("style"); // 取消禁止滚动

      this.$store.commit("clearSearchVal");
      this.$router.push({
        path: "/cathaya-science",
        query: {
          count: val,
        },
      });

      this.$store.commit("getInsightsMenu", val);
    },
    getTopicVal(val) {
      $(".breakpoint-on .site-nav-menu").hide(); // 移动端隐藏遮罩层
      $(".nav-toggler").removeClass("menu-opened");
      $("html,body").removeAttr("style"); // 取消禁止滚动

      this.$store.commit("clearSearchVal");
      this.$store.commit("getTopicVal", val);
    },
  },
  watch: {
    $route(to, from) {
      if (from.path != to.path) {
        if (this.cookie && this.cookie.indexOf("xsessionid=") != -1) {
          let strCookie = this.cookie.substr(11, this.cookie.length);
          insightsApi.getCookieTime(strCookie).then((res) => {
            // 监听路由，在登陆内，每次点击路由或者刷新页面，从新刷新cookie计算时间
            if (res.data.code == 0) {
              if (
                Date.now() >=
                res.data.data.expiration_time + 72 * 60 * 60 * 1000
              ) {
                this.$store.state.cookie = "";
                this.delCookie();
              }
            } else {
              console.log(res.data.msg);
            }
          });
        }
      }
    },
  },
  computed: {
    ...mapState(["cookie"]),
  },
};
</script>
<style lang="scss" scoped>
.iosPositon {
  position: fixed;
}

.main {
  position: absolute;
}

.active {
  span {
    color: #269aff;
  }

  ::v-deep .el-icon-arrow-right {
    color: #269aff;
  }
}

@media (max-width: 1200px) {
}
</style>
