import axios from 'axios';
import store from '@/store'

// var getLanguage = localStorage.getItem('getLanguage')
const request = axios.create({
    baseURL: '/api', 
    timeout: 100000, // 请求超时，5000毫秒
    headers: {
        'Content-Type': 'application/json; charset=utf-8;application/x-www-form-urlencoded',
        // "language":getLanguage
    }
})
// 根据各个环境
var url = window.location.href;
if(url.indexOf('test.141data-med.com.cn')!=-1){
    // process.env.NODE_ENV = 'development'
    store.state.NODE_ENV="development"
}else if(url.indexOf('www.cathayaresearch.com')!=-1){
    store.state.NODE_ENV="production"
}else{{
    store.state.NODE_ENV="development"
}}

let getCookie = document.cookie
store.state.cookie = getCookie.indexOf('xsessionid=') !=-1 ? getCookie : ''

// 请求拦截器
request.interceptors.request.use(config => {
    return config
}, error => {
    // 出现异常
    return Promise.reject(error);
})

// 响应拦截器
request.interceptors.response.use(response =>{
    return response
}, error => {
    return Promise.reject(error);
})

export default request;
