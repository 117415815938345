module.exports = {
  route:{
    SERVICES:'SERVICES',
    SOLUTIONS:'SOLUTIONS',
    INSIGHTS:'INSIGHTS',
    CONTACT:'CONTACT',
    LOGIN:'Client Login',
    /**
     * main 首页内容区
     */
    largeScreen:{
      fullOne:{
        worldOne:'Full suite of world-class',
        worldTwo:'RESEARCH & ANALYTICS services',
        worldThr:'by experts determined to drive clients’ success',
        worldFour:'Explore our research services'
      },
      fullTwo:{
        worldOne:'Multi-cultural team globally to provide strong support for',
        worldTwo:'GLOBAL CONSULTATION, LOCALIZATION & MANAGEMENT',
        worldThr:'of data collection, research & market intelligence projects',
        worldFour:'Learn more about our global experience'
      },
      fullThr:{
        worldOne:'High',
        worldTwo:'High',
        worldThr:'to help tackle business challenges and enhance market decisions globally ',
        worldFour:'Discover our panel app and data solutions'

      },
      fullFour:{
        worldOne:'Understanding a market is just a click away',
        worldTwo:'GET INSTANT QUOTE',
        worldThr:'',
        worldFour:'Quote Now'
      }
    },
    insights:{
      title:'Featured Industries',
      sectionTitle1:{
        title:'Pharmaceutical & Medical',
        more:'Learn More'
      },
      sectionTitle2:{
        title:'Life Science',
        more:'Learn More'
      },
      sectionTitle3:{
        title:'High-Tech & Telecom',
        more:'Learn More'
      }
    },
    dataCollection:{
      text1:'Leading-edge software tool for',
      text2:'Instant Bid & Cost-Efficient Data Collection',
      text3:'Understanding a market is just a click away',
      text4:'Request Instant Bid'
    },
    catPanel:{
      text1:'Global Insights',
      text2:'Impact of Covid-19 – Chinese Physicians’ Perspective',
      text3:'How would Covid-19 change their life and work? Would Chinese physicians opt for more domestic or imported products? How do they view the vaccines? What would they do/buy when the pandemic is over? Read our new report to learn about the views of doctors from our China panel.',
      text4:'Learn More'
    },
    contactUs:{
      text1:'Have any data collection, analytics or market research inquiries?',
      text2:'contact Us' 
    },
    /**
     * footer
     */
    footer:{
      company:'Company',
      services:'Services',
      contact:'Contact',
      career:'Career',

      researchPanels:'Research Panels',
      getQuote:'Get Quote',
      clientRegistration:'Client Registration',
      privacyPolicy:'Privacy Policy',

      fromUs:'Receive global market insights from us.',
      enterMail:'ENTER YOUR EMAIL',
      btn:'Subscribe',

      cathaya:'© 2021 · Cathaya Technologies LLC'
    }
  }
}