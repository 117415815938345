import request from "@/utils/request";

export default {
  //登出
  logout(data) {
    return request({
      headers: {
        xsessionid: data,
      },
      url: "/login/logout",
      method: "post",
    });
  },
  // 行业类型列表
  getInsightCategory() {
    return request({
      url: "/cms_industries/get_list",
      method: "get",
    });
  },
  // 行业主题列表
  getInsightTopic() {
    return request({
      url: "/cms_insight_topic/get_list",
      method: "get",
    });
  },
  // 行业市场列表
  getInsightMarket() {
    return request({
      url: `/cms_market/get_list`,
      method: "get",
    });
  },
  //获取关键词
  getInsightTag() {
    return request({
      url: `/cms_tag/get_list`,
      method: "get",
    });
  },
  // 获取定向定量
  getInsightNewsTag(data) {
    return request({
      url: `/cms_news/get_list?page=1&page_num=3&news_tag=${data.news_tag}`,
      method: "get",
    });
  },
  // 获取文章报告列表
  getInsightNewsType(data) {
    if (data.home == "home") {
      //首页
      return request({
        url: `/cms_news/get_list?page=1&page_num=2&news_type=${data.news_type}`,
        method: "get",
      });
    } else {
      return request({
        url: `/cms_news/get_list?page=1&page_num=3&news_type=${data.news_type}`,
        method: "get",
      });
    }
  },
  // 获取文章列表子页
  getInsightNews(data) {
    return request({
      url: `/cms_news/get_list?page=${data.page}&page_num=${data.page_num}&topic_id=${data.topic_id}&industries_id=${data.industries_id}&market_id=${data.market_id}&news_type=${data.news_type}&tag_id=${data.tag_id}`,
      method: "get",
    });
  },
  // 查找文章列表
  getInsightNewsDetails(data) {
    return request({
      url: `/cms_news/get_list?news_id=${data.news_id}&page=${data.page}&page_num=${data.page_num}&is_login_visible=${data.is_login_visible}&topic_id=${data.topic_id}&industries_id=${data.industries_id}&market_id=${data.market_id}&news_type=${data.news_type}&tag_id=${data.tag_id}&search_content=${data.search_content}`,
      method: "get",
    });
  },
  // 搜索文章
  getInsightSearch(data) {
    return request({
      url: `/cms_news/get_list?page=${data.page}&page_num=${data.page_num}&search_content=${data.search_content}&news_type=${data.news_type}`,
      method: "get",
    });
  },
  // 文章报告下载
  detailesDownload(data) {
    return request({
      url: `/cms_news/news_download?news_id=${data.news_id}`,
      method: "get",
      responseType: "blob",
    });
  },
  // 获取文章详情
  getInsightNewsDetailsContent(data) {
    return request({
      url: `/cms_news/get_news_details?news_id=${data.news_id}`,
      method: "get",
    });
  },
  // 用户订阅
  getInsightSaveEmail(data) {
    return request({
      url: `/cms_user_subscription/save_data`,
      method: "post",
      data,
    });
  },
  // 监听路由，如果登陆状态下，点击页面刷新cookie
  getCookieTime(data) {
    return request({
      headers: {
        xsessionid: data,
      },
      url: `/get_data_expiration_time/get_expiration_time`,
      method: "get",
    });
  },
};
