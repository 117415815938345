import Vue from "vue";
import Vuex from "vuex";
import persist from "vuex-persistedstate";

Vue.use(Vuex);
let store = new Vuex.Store({
  state: {
    cookie: "",
    language: "en",
    insightsObj: {
      count: "",
      insightsVal: "",
      searchVal: "",
    },
    widthWidow: "",
    NODE_ENV: "",
    normalList: [],
  },
  getters: {},
  mutations: {
    clearSearchVal(state) {
      state.insightsObj.count = "";
      state.insightsObj.insightsVal = "";
      state.insightsObj.searchVal = "";
    },
    getInsightsMenu(state, payload) {
      state.insightsObj.count = payload;
    },
    getTopicVal(state, payload) {
      state.insightsObj.insightsVal = payload;
    },
    getSearchVal(state, payload) {
      state.insightsObj.searchVal = payload;
    },
    clearCookie(state) {
      state.cookie = "";
    },
    setNormalList(state, value) {
      state.normalList = value;
    },
  },
  actions: {},
  plugins: [
    new persist({
      storage: window.sessionStorage,
    }),
  ],
});
export default store;
