<template>
  <div class="cathaya-footer">
    <!-- 返回顶部 -->
    <el-backtop />
    <div class="footer_us">
      <el-row :gutter="0">
        <el-col :xs="24" :sm="24" :md="8" :lg="8">
          <div class="grid-content bg-purple-dark">
            <h3 class="title">About Cathaya Research</h3>
            <div class="ul_box">
              <ul class="ul_lf">
                <li><router-link to="/cathaya-about">Company</router-link></li>
                <li>
                  <router-link to="/cathaya-services">Services</router-link>
                </li>
                <li>
                  <router-link to="/cathaya-careerForm">Contact</router-link>
                </li>
                <li><router-link to="/cathaya-career">Career</router-link></li>
              </ul>
              <ul class="ul_rg">
                <li><a href="/cathaya-sampleService">Research Panels</a></li>
                <li>
                  <a href="/cathaya-getquote">Get Quote</a>
                </li>
                <li>
                  <a
                    :href="
                      $store.state.NODE_ENV === 'development'
                        ? 'http://quotationserver.141data-med.com.cn/register'
                        : 'https://u.cathayaresearch.com/register'
                    "
                    >Client Registration</a
                  >
                </li>
                <li>
                  <router-link to="/cathaya-privacy-policy"
                    >Privacy Policy</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8">
          <div class="grid-content bg-purple-dark">
            <h3 class="title">Contact Us</h3>
            <p class="p1">
              General Inquiries<br />
              <a href="mailto:inquiry@cathayaresearch.com">
                inquiry@cathayaresearch.com</a
              >
            </p>
            <p class="p2">
              Quote Request<br />
              <a href="mailto:quotes@cathayaresearch.com">
                quotes@cathayaresearch.com</a
              >
            </p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8">
          <div class="grid-content bg-purple-dark">
            <h3 class="title">Subscribe to Cathaya Research Newsletter</h3>
            <p class="p1">Receive global market insights from us</p>
            <div class="form_box">
              <el-form :model="objectEmail" :rules="rules" ref="objectEmail">
                <el-form-item prop="email">
                  <el-input
                    v-model.trim="objectEmail.email"
                    placeholder="ENTER YOUR EMAIL"
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-button
                href="javascript:;"
                :loading="emailLoading"
                @click="toNewsletterPage('objectEmail')"
                >Subscribe</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="copyright">©{{ dateYear }} – Cathaya Technologies LLC</div>
    <div
      class="cat_cookie animate__animated animate__fadeInUp animate__delay-.0s"
      v-show="cookieIsShow"
    >
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="16">
          <div class="cat_cookie_more">
            <div>
              We use cookies to ensure you get the best experience. By using our
              website you agree to our
              <div class="cat_cookie_more_hx">
                <a href="javascript:;" @click="cookieReadMore">
                  Cookie Policy.</a
                >
                <span></span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8">
          <div class="cat_cookie_main_link">
            <div class="cat_cookie_more_hx">
              <a href="javascript:;" @click="cookieReadMore">Read more</a>
              <span></span>
            </div>
            <a href="javascript:;" @click="agreeHanle">Agree and Continue</a>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :width="dialogWidth"
      :before-close="handleClose"
      class="cookie_more"
    >
      <h2 v-show="cookieTitleShow" class="cookie_title">Cookie Policies</h2>
      <p>
        Cookies are important to ensure the proper functioning of a website. We
        use cookies to remember log-in details and provide secure log-in,
        collect some usage statistics to improve the site functionality and
        deliver the content tailored to your needs. Click “Agree and Continue”
        to accept cookies and go directly to our site. You can also view the
        cookie policies with detailed descriptions of the types of cookies and
        choose whether to accept certain cookies.
        <span @click="cookieMore()" v-show="cookielinkShow"
          >Cookie Policies</span
        >
      </p>
      <div v-show="cookieContentIsShow">
        <p>
          Cookies are small information files that are placed on your device
          when you visit our website. We use several cookie types, and each type
          performs a different function. Information in the cookies may be
          linked to personal data. Cookies enable you to navigate between web
          pages efficiently and store your preferences, and they generally
          improve your web experience. Typical types of cookies used on our
          website include: (i) required, these are cookies required to enable
          core site functionality; (ii) functional, these are cookies that allow
          us to analyze our site usage in order to improve your usage
          experience; (iii) content cookies, these are cookies that we may use
          to deliver contents that are relevant to your interests. Please note
          that sometimes the classification of a cookie may overlap (for
          instance, a content cookie may also qualify as functional as it is
          necessary to enable you to view certain website content).
        </p>
        <p>
          You can change your browser settings to disable selected cookie
          categories. However, keep in mind that deleting cookies will prevent
          you from using many of the features of the website. We use cookies to
          maintain your log-in status and control your access as you move
          through the site. Cookies also enable us to personalize your
          preferences.
        </p>
        <p>
          We aggregate statistics for analysis regarding the application types
          used to browse our website and the regions from which such browsing
          takes place. Please be assured that this information is not personally
          identifiable.
        </p>
        <p>
          In addition to cookies, Cathaya's website may include features that
          enable sharing via third-party social media applications, such as
          Facebook's like button and LinkedIn and Twitter widgets. Any personal
          information you provide via such social media applications may be
          collected and used by other users of that social media application,
          and such interactions are governed by the respective privacy policies
          of the companies that provide those applications. We do not bear
          responsibility for those companies or their use of your information.
        </p>
        <p>
          Cathaya also uses in-house and third-party analytics tools to track
          user behavior when you visit our website to improve user experience
          for our website visitors.
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import insightsApi from "@/api/insights";
export default {
  name: "cathayaHome",
  data() {
    return {
      env: "",
      dateYear: "",
      objectEmail: {
        email: "",
      },
      dialogWidth: "60%",
      cookieIsShow: true,
      dialogVisible: false,
      cookieContentIsShow: false,
      emailLoading: false,
      cookieTitleShow: false,
      cookielinkShow: true,
      rules: {
        email: [
          {
            type: "email",
            message: "Please enter your email address correctly.",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  mounted() {
    // 获取当前年份
    this.getDateYears();
    // 获取本地cookie，显示隐藏隐私政策
    let getCookie = localStorage.getItem("setCookie");
    this.cookieIsShow = getCookie ? false : true;
    //  根据分辨率显示弹窗的宽度
    if (this.$store.state.widthWidow <= 1200) {
      this.dialogWidth = "100%";
    }
  },
  methods: {
    getDateYears() {
      this.dateYear = new Date().getFullYear();
    },
    toNewsletterPage(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (this.objectEmail.email == "") return false;
        if (!valid) return false;
        this.emailLoading = true;
        let params = {
          user_email: this.objectEmail.email,
        };
        let emails = await insightsApi.getInsightSaveEmail(params);
        this.emailLoading = false; // 返回数据就隐藏圈
        if (emails.data.code == 0) {
          this.objectEmail.email = "";
          this.$router.push({
            path: "/cathaya-newsletter",
          });
        } else {
          this.$message.error(emails.data.msg);
        }
      });
    },
    agreeHanle() {
      this.cookieIsShow = false;
      localStorage.setItem("setCookie", this.cookieIsShow);
      // a 标签动态创建超链接，跳转返回当前页
      // if(!this.cookie){
      //    var a = document.createElement('a');
      //    a.href = 'http://quotationserver.141data-med.com.cn/login?redirect=%2Fhome';
      //    a.click()
      //    this.cookieIsShow = true
      // }else{
      //   this.cookieIsShow = false
      // }
    },
    cookieReadMore() {
      this.dialogVisible = true;
      this.cookieTitleShow = false;
      this.cookielinkShow = true;
    },
    cookieMore() {
      this.cookieContentIsShow = true;
      this.cookieTitleShow = true;
      this.cookielinkShow = false;
    },
    handleClose() {
      this.dialogVisible = false;
      this.cookieContentIsShow = false;
    },
  },
  computed: {
    ...mapState(["cookie"]),
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item.is-error .el-input__inner {
  border-color: #ccc;
}
::v-deep .el-form-item.is-error .el-input__inner:focus {
  border-color: #ccc;
}
::v-deep .el-form-item__error {
  line-height: 2;
  font-size: 18px;
  width: 150%;
  position: absolute;
  z-index: 999;
  left: 0;
}
::v-deep .el-dialog__body {
  padding: 0 60px;
  word-wrap: break-word;
  p {
    margin-top: 12px;
    font-size: 18px;
    span {
      font-weight: bold;
    }
  }
  // div{
  //   margin-top: 16px;
  // }
}
::v-deep .cookie_more {
  h2 {
    text-align: center;
  }
  p {
    span {
      text-decoration: underline;
    }
  }
}
::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
::v-deep .el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
  padding: 0 24px;
  word-break: keep-all;
}
</style>
