<template>
  <div id="app">
    
      <!-- 公用头部 -->
      <Header></Header>
        <!-- 可视化内容区 -->
      <transition>
        <router-view />
      </transition>
  
      <!-- 公用底部 -->
      <Footer></Footer>
    
  </div>
</template>

<script>
import Header from "@/components/Header"
import Footer from "@/components/Footer"
export default {
  name: "App",
  components: {
    Header,
    Footer
  }
};
</script>

<style>
/* .fade-enter-active, .fade-leave-active{
  transition: opacity 1.5s ;
}
.fede-enter, .fade-leave-to{
  opacity: 0;
} */
</style>
