import Vue from 'vue'
import router from './router'
import App from './App.vue'
import store from './store'
// import 'lib-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
Vue.prototype.$ajax = axios

// import '@/utils/rem'

// 滚动插件

//国际语言翻译
// import vGoogleTranslate from '@/packages/index';
// Vue.use(vGoogleTranslate);

//基于本地的语言翻译
import i18n from './i18n/i18n'

Vue.use(ElementUI);
Vue.config.productionTip = false
import "animate.css"
// import 'swiper/dist/css/swiper.min.css'
// import 'swiper/dist/js/swiper.min'
//样式根目录
import '@/assets/css/index.scss'

// import '@/utils/request'
new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount("#app");
